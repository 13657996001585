import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import Search from '@/components/molecules/Search'
import Menu from '@/components/atoms/Menu'
import RangeInput from '@/components/molecules/RangeInput'
import useMatchMedia from '@/hooks/useMatchMedia'
import useQueryParams from '@/hooks/useQueryParams'

import { URL_SEARCH_PARAMS } from '@/helpers/consts'
import { useCommercialFilters } from './useCommercialFilters'
import { CommercialMenuVariant, FilterTrait } from './types'
import * as styles from './styles.module.css'
import { filterItems } from './helpers'

interface Props {
	properties: PropertyType[]
	submitFilters: (newProperties: PropertyType[]) => void
	children: React.ReactNode
}

function CommercialFilters({ properties, submitFilters, children }: Props) {
	const {
		activeMenu,
		openMenu,
		closeMenu,
		handleMenuSubmitClick,
		handleRangeChange,
		handleSearchChange,
		handleSearchSubmit,
		getMenuTitle,
		renderMenuContent,
		resetFilters,
		filteredItemsCount,
		filters,
		handleTypeCheckboxChange,
	} = useCommercialFilters(properties, submitFilters)
	const isDesktop = useMatchMedia('(min-width: 992px)')
	const typeParam = useQueryParams(URL_SEARCH_PARAMS.TYPE)
	const [isUrlParam, setIsUrlParam] = useState(false)
	const menuItems: { title: string; variant: CommercialMenuVariant }[] = [
		{ title: 'City', variant: FilterTrait.City },
		{ title: 'Project Type', variant: FilterTrait.ProjectType },
		{ title: isDesktop ? 'Sq. Ft Range' : 'Sq. Ft', variant: FilterTrait.SqFtRange },
	]

	useEffect(() => {
		if (typeParam) {
			handleTypeCheckboxChange(typeParam)
			setIsUrlParam(true)
		}
	}, [typeParam])

	useEffect(() => {
		if (isUrlParam) {
			resetFilters()
			submitFilters(filterItems(properties, filters))
		}

		return () => {
			setIsUrlParam(false)
		}
	}, [isUrlParam])

	return (
		<section>
			<div className={clsx('container', styles.wrapper)}>
				<div className={styles.searchWrapper}>
					<div>{isDesktop && children}</div>
					<Search onChange={handleSearchChange} onSubmit={handleSearchSubmit} value={filters.search} />
				</div>
				<div className={styles.checkboxFilters}>
					<ul>
						{menuItems.map(({ title, variant }) => (
							<li key={title}>
								<button
									className={clsx(styles.filterBtn, {
										[styles.filterBtnInactive]: variant === FilterTrait.SqFtRange,
									})}
									onClick={openMenu(variant)}
								>
									{title}
									<i
										className={clsx(styles.arrowIcon, {
											[styles.active]: activeMenu === variant,
											[styles.hidden]: variant === FilterTrait.SqFtRange,
										})}
									/>
								</button>
								<div>
									{isDesktop && variant === FilterTrait.SqFtRange && (
										<div className={styles.rangeInputContainer}>
											<RangeInput placeholder="MIN" onChange={handleRangeChange('min')} />
											<span>-</span>
											<RangeInput placeholder="MAX" onChange={handleRangeChange('max')} />
										</div>
									)}
								</div>
								<Menu
									open={activeMenu === variant}
									onClose={closeMenu}
									title={getMenuTitle(variant)}
									buttonProps={{
										primary: { label: `Filter (${filteredItemsCount})`, onClick: handleMenuSubmitClick },
										secondary: { label: 'Cancel', onClick: closeMenu },
									}}
								>
									{renderMenuContent(variant)}
								</Menu>
							</li>
						))}
					</ul>
				</div>
			</div>
		</section>
	)
}

export default CommercialFilters
