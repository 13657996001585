import React, { ChangeEvent } from 'react'

import * as styles from './styles.module.css'

type RangeInputPropsType = {
	placeholder: string
	onChange: (value: number) => void
}

function RangeInput({ placeholder, onChange }: RangeInputPropsType) {
	const validate = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (!/[0-9]/.test(e.key)) {
			e.preventDefault()
		}
	}

   function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
      const {value} = e.target
      if(value !== '') {
         const parsedValue = Number(value)
         onChange(parsedValue)
      } else {
         onChange(0)
      }
   }
	return (
		<input
			className={styles.input}
			type="number"
			placeholder={placeholder}
			onKeyPress={validate}
			onChange={handleOnChange}
			min={0}
		/>
	)
}

export default RangeInput
