import React, { ChangeEvent, useCallback, useEffect, useState, useMemo } from 'react'

import CheckboxItem from '@/components/molecules/CheckboxItem'
import RangeItem from '@/components/molecules/RangeItem'
import useMatchMedia from '@/hooks/useMatchMedia'
import useScrollLock from '@/hooks/useScrollLock'

import { CommercialCheckboxFilterTrait, CommercialFilterType, CommercialMenuVariant, FilterTrait } from './types'
import { filterItems, getAvailableFilters } from './helpers'

const RANGE_DEFAULT_STEP = 20
const RANGE_MOBILE_DEFAULT_VALUE_MIN = 80
const RANGE_MOBILE_DEFAULT_VALUE_MAX = 800

export const useCommercialFilters = (items: PropertyType[], submitFilters: (newProperties: PropertyType[]) => void) => {
	const [filters, setFilters] = useState<CommercialFilterType>(getAvailableFilters(items))
	const [activeMenu, setActiveMenu] = useState<CommercialMenuVariant | null>(null)
	const { lockScroll, unlockScroll } = useScrollLock()
	const isMobile = useMatchMedia('(max-width: 991px)')
	const filteredItems = useMemo(() => filterItems(items, filters), [filters])
	const filteredItemsCount = filteredItems.length

	useEffect(() => {
		const submit = (e: KeyboardEvent) => {
			if (e.key === 'Enter') {
				submitFilters(filterItems(items, filters))
			}
		}
		window.addEventListener('keydown', submit)
		return () => window.removeEventListener('keydown', submit)
	}, [filters])

	const resetFilters = () => {
		setFilters(getAvailableFilters(items))
	}

	const handleSearchChange = (value: string) => {
		setFilters((prevFilters) => ({
			...prevFilters,
			[FilterTrait.Search]: value,
		}))
	}

	const handleSearchSubmit = useCallback(() => {
		submitFilters(filterItems(items, filters))
	}, [filters, items])

	const handleRangeChange = useCallback(
		(rangeItem: 'min' | 'max') => (value: number) => {
			const newValue = value ?? 0
			setFilters((prevFilters) => ({
				...prevFilters,
				[FilterTrait.SqFtRange]: { ...prevFilters[FilterTrait.SqFtRange], [rangeItem]: newValue },
			}))
		},
		[]
	)

	const handleRangeIncrement = useCallback(
		(rangeItem: 'min' | 'max') => () => {
			setFilters((prevFilters) => {
				const currValue = prevFilters[FilterTrait.SqFtRange][rangeItem]
				if (currValue === null) {
					const newValue =
						(rangeItem === 'min' ? RANGE_MOBILE_DEFAULT_VALUE_MIN : RANGE_MOBILE_DEFAULT_VALUE_MAX) +
						RANGE_DEFAULT_STEP
					return {
						...prevFilters,
						[FilterTrait.SqFtRange]: { ...prevFilters[FilterTrait.SqFtRange], [rangeItem]: newValue },
					}
				}
				const newValue = Number(currValue) + RANGE_DEFAULT_STEP
				return {
					...prevFilters,
					[FilterTrait.SqFtRange]: { ...prevFilters[FilterTrait.SqFtRange], [rangeItem]: newValue },
				}
			})
		},
		[]
	)

	const handleRangeDecrement = useCallback(
		(rangeItem: 'min' | 'max') => () => {
			setFilters((prevFilters) => {
				const currValue = prevFilters[FilterTrait.SqFtRange][rangeItem]
				if (currValue === null) {
					const newValue =
						(rangeItem === 'min' ? RANGE_MOBILE_DEFAULT_VALUE_MIN : RANGE_MOBILE_DEFAULT_VALUE_MAX) -
						RANGE_DEFAULT_STEP
					return {
						...prevFilters,
						[FilterTrait.SqFtRange]: { ...prevFilters[FilterTrait.SqFtRange], [rangeItem]: newValue },
					}
				}
				if (Number(currValue) >= RANGE_DEFAULT_STEP) {
					const newValue = Number(currValue) - RANGE_DEFAULT_STEP
					return {
						...prevFilters,
						[FilterTrait.SqFtRange]: { ...prevFilters[FilterTrait.SqFtRange], [rangeItem]: newValue },
					}
				}
				return {
					...prevFilters,
					[FilterTrait.SqFtRange]: { ...prevFilters[FilterTrait.SqFtRange], [rangeItem]: 0 },
				}
			})
		},
		[]
	)

	const handleCheckboxChange = useCallback(
		(trait: CommercialCheckboxFilterTrait) => (e: ChangeEvent<HTMLInputElement>) => {
			setFilters((prevFilters) => {
				const idx = prevFilters[trait].findIndex((el) => el.label === e.target.name)
				if (idx >= 0) {
					const newItem = { ...prevFilters[trait][idx], checked: e.target.checked }
					const newState = { ...prevFilters }
					newState[trait][idx] = newItem
					return newState
				}
				return prevFilters
			})
		},
		[]
	)

	const handleTypeCheckboxChange = (name: string) => {
		setFilters((prevFilters) => {
			const idx = prevFilters.project_type.findIndex((el) => el.label === name)
			if (idx >= 0) {
				const newItem = { ...prevFilters.project_type[idx], checked: true }
				const newState = {
					...prevFilters,
					project_type: prevFilters.project_type.map((item) => ({ ...item, checked: false })),
				}
				newState.project_type[idx] = newItem
				return newState
			}
			return prevFilters
		})
	}

	const openMenu = (menuVariant: CommercialMenuVariant) => () => {
		setActiveMenu(menuVariant)
		if (isMobile) {
			lockScroll()
		}
	}

	const closeMenu = () => {
		setActiveMenu(null)
		if (isMobile) {
			unlockScroll()
		}
	}

	const handleMenuSubmitClick = () => {
		submitFilters(filterItems(items, filters))
		closeMenu()
	}

	const getMenuTitle = useCallback(
		(variant: CommercialMenuVariant) =>
			({
				[FilterTrait.City]: 'Cities',
				[FilterTrait.ProjectType]: 'Project Type',
				[FilterTrait.SqFtRange]: 'Sq. Ft. Range',
			}[variant]),
		[]
	)

	const renderMenuContent = useCallback(
		(variant: FilterTrait): React.ReactElement | React.ReactElement[] | null => {
			switch (variant) {
				case FilterTrait.City:
				case FilterTrait.ProjectType:
					return filters[variant].map(({ label, checked }) => (
						<CheckboxItem
							key={label}
							label={label}
							name={label}
							checked={checked}
							onChange={handleCheckboxChange(variant)}
						/>
					))
				case FilterTrait.SqFtRange:
					return (
						<>
							<RangeItem
								label="Min"
								value={filters[FilterTrait.SqFtRange].min ?? RANGE_MOBILE_DEFAULT_VALUE_MIN}
								onChange={handleRangeChange('min')}
								limit={filters[FilterTrait.SqFtRange].minLimit}
							/>
							<RangeItem
								label="Max"
								value={filters[FilterTrait.SqFtRange].max ?? RANGE_MOBILE_DEFAULT_VALUE_MAX}
								onChange={handleRangeChange('max')}
								limit={filters[FilterTrait.SqFtRange].maxLimit}
							/>
						</>
					)
				default:
					return null
			}
		},
		[filters, handleCheckboxChange]
	)

	return {
		activeMenu,
		openMenu,
		closeMenu,
		handleMenuSubmitClick,
		getMenuTitle,
		renderMenuContent,
		handleCheckboxChange,
		handleRangeChange,
		filteredItemsCount,
		handleSearchChange,
		handleSearchSubmit,
		filters,
		handleTypeCheckboxChange,
		resetFilters,
	}
}
