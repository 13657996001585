import React, { useState } from 'react'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

import * as styles from './styles.module.css'

type RangeItemPropsType = {
	label: string
	value: number
	limit: number
	onChange: (value: number) => void
}

function RangeItem({ label, value, onChange, limit }: RangeItemPropsType) {
	const [inputValue, setInputValue] = useState<number>(0)
	
   function handleOnChange(actualVal: number | number[]) {
      if(typeof actualVal === "number")
		setInputValue(actualVal)
	}


   function afterChange(actualVal: number | number[]) {
      if (typeof actualVal === 'number') onChange(actualVal)
   }

	return (
      <div className={styles.range_slider}>
         <span className={styles.range_slider_label}>{label}</span>
         <Slider
            value={inputValue}
            onChange={handleOnChange}
            onAfterChange={afterChange}
            max={limit}
            min={0}
            defaultValue={value}
            className={styles.range_slider_component}
            railStyle={{ backgroundColor: '#a5c28c', height: 14 }}
            trackStyle={{ backgroundColor: '#1b5722', height: 14 }}
            handleStyle={{
               borderColor: '#a5c28c',
               height: 28,
               width: 28,
               marginTop: -7,
               backgroundColor: '#1b5722',
               opacity: 1,
            }}
         />
         <span className={styles.range_slider_value}>{inputValue}</span>
      </div>
	)
}

export default RangeItem
