import { composeCheckboxRecord } from '@/utils/composeCheckboxRecord'
import { reduceCheckboxRecordsToValues } from '@/utils/reduceCheckboxRecordsToValues'
import { isEmpty } from '@/utils/isEmpty'

import { CommercialFilterType, FilterTrait } from './types'
import { UniqueValuesType } from '../Filters/types'

const getAvailableFilters = (properties: PropertyType[]) => {
	const uniqueValues = properties.reduce<UniqueValuesType>(
		(acc, { city, property_types, sqFeetMax, sqFeetMin }) => {
			let currentCity = ''
			let currentPropertyTypes: string[] = []
			let currentMaxLimit = acc.square_feet_range.maxLimit
			let currentMinLimit = acc.square_feet_range.minLimit

			if (city) {
				currentCity = city
			}
			if (property_types) {
				currentPropertyTypes = property_types.map((el) => el.type)
			}

			if (sqFeetMax) {
				if (currentMaxLimit < sqFeetMax) {
					currentMaxLimit = sqFeetMax
				}
			}

			if (sqFeetMin) {
				if (currentMinLimit < sqFeetMin) {
					currentMinLimit = sqFeetMin
				}
			}

			const newCities = [...new Set([...acc[FilterTrait.City], currentCity])].filter(Boolean)
			const newProjectTypes = [...new Set([...acc[FilterTrait.ProjectType], ...currentPropertyTypes])]
			return {
				...acc,
				[FilterTrait.City]: newCities,
				[FilterTrait.ProjectType]: newProjectTypes,
				[FilterTrait.SqFtRange]: { min: 0, max: 0, maxLimit: currentMaxLimit, minLimit: currentMinLimit },
			}
		},
		{
			[FilterTrait.City]: [],
			[FilterTrait.ProjectType]: [],
			[FilterTrait.SqFtRange]: { min: 0, max: 0, maxLimit: 0, minLimit: 0 },
		}
	)

	return {
		[FilterTrait.Search]: '',
		[FilterTrait.City]: uniqueValues[FilterTrait.City].sort().map(composeCheckboxRecord),
		[FilterTrait.ProjectType]: uniqueValues[FilterTrait.ProjectType].map(composeCheckboxRecord),
		[FilterTrait.SqFtRange]: uniqueValues[FilterTrait.SqFtRange],
	}
}

const filterItems = (properties: PropertyType[], filters: CommercialFilterType) => {
	const searchFilter = filters[FilterTrait.Search].toLowerCase()
	const cityFilters = filters[FilterTrait.City].reduce(reduceCheckboxRecordsToValues, [])
	const projectTypeFilters = filters[FilterTrait.ProjectType].reduce(reduceCheckboxRecordsToValues, [])
	const rangeFilters = [filters[FilterTrait.SqFtRange].min, filters[FilterTrait.SqFtRange].max]
	const filterValues = {
		[FilterTrait.Search]: searchFilter,
		[FilterTrait.City]: cityFilters,
		[FilterTrait.ProjectType]: projectTypeFilters,
		[FilterTrait.SqFtRange]: rangeFilters,
	}
	return properties.filter((property) => {
		return Object.keys(filterValues).every((accessor) => {
			switch (accessor) {
				case FilterTrait.Search: {
					const { name, address, city, state } = property
					const searchValue = filterValues[FilterTrait.Search]
					return (
						name.toLowerCase().includes(searchValue) ||
						address?.toLowerCase().includes(searchValue) ||
						city?.toLowerCase().includes(searchValue) ||
						state?.toLowerCase().includes(searchValue)
					)
				}
				case FilterTrait.City: {
					if (isEmpty(filterValues[FilterTrait.City])) {
						return true
					}
					const propertyCity = property.city?.toLowerCase()
					const searchValues = filterValues[FilterTrait.City]
					return searchValues.some((value) => value === propertyCity)
				}
				case FilterTrait.ProjectType: {
					if (isEmpty(filterValues[FilterTrait.ProjectType])) {
						return true
					}
					const propertyProjectTypes = property.property_types?.map((projectType) =>
						projectType.type.toLowerCase()
					)
					const searchValues = filterValues[FilterTrait.ProjectType]
					return searchValues.some((value) => propertyProjectTypes?.includes(value))
				}
				case FilterTrait.SqFtRange: {
					const { property_units: units } = property
					const [min, max] = filterValues[FilterTrait.SqFtRange]
					if ((units?.length == 0) || (!units)) {
						return false 
					}
					for (const unit of units) {
						if (!unit.available_sq_feet || (unit.available_sq_feet == 0)) {
							continue
						}
						if (min === 0 && max === 0) {
							return true
						}
						if (min === 0 && max && unit.available_sq_feet <= max) {
							return true
						}
						if (max === 0 && min && unit.available_sq_feet >= min) {
							return true
						}
						if (unit.available_sq_feet >= min! && unit.available_sq_feet <= max!) {
							return true
						}
					}
					return false
				}
				default:
					return true
			}
		})
	})
}

export { getAvailableFilters, filterItems }
