import React, { useEffect, useMemo, useState } from 'react'
import { graphql } from 'gatsby'

import Layout from '@/components/organisms/Layout'
import Seo from '@/components/organisms/Seo'
import HeroSlides from '@/components/organisms/HeroSlides'
import PropertiesList from '@/components/organisms/PropertiesList'
import CommercialFilters from '@/components/organisms/CommercialFilters'
import ToggleLayout from '@/components/molecules/ToggleLayout'
import { getValueFromLs, setValueToLs } from '@/utils/handleLocalStorage'
import { LOCALSTORAGE_KEYS } from '@/helpers/consts'

type CommercialQueryType = {
	content?: {
		seo?: SeoMetagagsType
		section_hero?: HeroSlidesType
		map_central_point?: MapCentralPoint
	}
	properties?: {
		edges: { node: PropertyType }[]
	}
}

interface Props {
	data: CommercialQueryType
}

export default function Commercial({ data }: Props) {
	const [layout, setLayout] = useState<LayoutToggleType>('grid')
	const { properties, content } = data || {}
	const initialData = useMemo(() => {
		const newProperties: PropertyType[] = properties?.edges.map(({ node }) => ({ ...node, type: 'commercial' })) || []
		return { properties: newProperties }
	}, [properties])
	const [filteredItems, setFilteredItems] = useState(initialData.properties)

	function handleItemsChange(propertyItems: PropertyType[]) {
		setFilteredItems(propertyItems)
	}

	function handleChangeLayout(type: LayoutToggleType) {
		setValueToLs<LayoutToggleType>(LOCALSTORAGE_KEYS.LAYOUT_MODE, type)
		setLayout(type)
	}

	useEffect(() => {
		const newLayout = getValueFromLs<LayoutToggleType>(LOCALSTORAGE_KEYS.LAYOUT_MODE, 'grid')
		if (newLayout && newLayout !== layout) {
			setLayout(newLayout)
		}
	}, [])

	return (
		<Layout>
			<div className="background commercial">
				<Seo {...content?.seo} />
				<HeroSlides {...content?.section_hero} />
				<CommercialFilters properties={initialData.properties} submitFilters={handleItemsChange}>
					<ToggleLayout activeLayout={layout} onChangeLayout={handleChangeLayout} />
				</CommercialFilters>
				<PropertiesList properties={filteredItems} activeLayout={layout} mapSetup={content?.map_central_point}>
					<ToggleLayout activeLayout={layout} onChangeLayout={handleChangeLayout} />
				</PropertiesList>
			</div>
		</Layout>
	)
}

export const commercialQuery = graphql`
	query {
		content: strapiPageCommercial {
			seo {
				description
				og_image {
					url
				}
				title
			}

			section_hero {
				content {
					data {
						content
					}
				}
				bg_dark_level
				slides {
					localFile {
						id
						childImageSharp {
							gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, JPG])
						}
					}
				}
			}

			map_central_point {
				latitude
				longitude
				zoom
			}
		}
		properties: allStrapiProperty(sort: { fields: name, order: ASC }) {
			edges {
				node {
					property_units_resolved {
						available_sq_feet
						price_per_sq_ft
						is_available
						street_address
					}
					address
					city
					id
					name
					state
					tags {
						header
						id
						text
					}
					zip
					images {
						alternativeText
						localFile {
							childImageSharp {
								gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, JPG], width: 800, height: 600)
							}
						}
					}
					city
					property_types {
						type
					}
					property_units {
						available_sq_feet
					}
					coordinate {
						latitude
						longitude
					}
				}
			}
		}
	}
`
